import './App.css';
import CarouselPage from './components/CarouselPage';



const App = () => {


  return (
    <div className="App">
      <CarouselPage/>

    </div>
  );
}

export default App;
